<template>
  <section>
    <div class="container flex flex-wrap justify-center gap-5 pt-10 pb-20 text-3xl min-[370px]:text-4xl sm:text-7xl text-slate-600">
      <UIcon name="i-simple-icons-bitcoin" />
      <UIcon name="i-simple-icons-applepay" />
      <UIcon name="i-simple-icons-googlepay" />
      <UIcon name="i-simple-icons-visa" />
      <UIcon name="i-simple-icons-mastercard" />
      <UIcon name="i-simple-icons-americanexpress" />
    </div>
  </section>
</template>
